import React from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";
import { Body, Caption } from "helpers/text";
import MainContainer from "helpers/MainContainer";
import d2015 from "../../images/past/droidkaigi2015.png";
import d2016 from "../../images/past/droidkaigi2016.png";
import d2017 from "../../images/past/droidkaigi2017.png";
import d2018 from "../../images/past/droidkaigi2018.png";
import d2019 from "../../images/past/droidkaigi2019.png";
import d2020 from "../../images/past/droidkaigi2020.svg";
import d2021 from "../../images/past/droidkaigi2021.svg";
import { makeText } from "helpers/i18n";
import Link from "helpers/Link";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    timetable: <Link to="/timetable">タイムテーブル</Link>,
    cfs: (
      <a href="https://docs.google.com/document/d/10b_i4hHGjA8c2Yuu0Ljog3D7EEE4GPNOacJ8wo5AkTI">
        募集要項
      </a>
    ),
    proposals: (
      <a href="https://docs.google.com/spreadsheets/d/1QeFqzW-AA2afcGRdbacO2Azd83ckxPrdWG4P9dmroxM/edit">
        投稿されたセッション
      </a>
    ),
    codeOfConduct: (
      <a href="http://www.association.droidkaigi.jp/code-of-conduct.html">
        行動規範
      </a>
    ),
    mailto: <a href="mailto:info@droidkaigi.jp">お問い合わせ</a>,
    privacyPolicy: (
      <a href="http://www.association.droidkaigi.jp/privacy.html">
        プライバシーポリシー
      </a>
    ),
    license:
      "Android、Google Play、Google Play ロゴは、Google LLC の商標です。",
    pastKaigiTitle: "これまでの DroidKaigi",
    pastKaigis: {
      url2015: "https://droidkaigi.github.io/2015/",
      url2016: "https://droidkaigi.github.io/2016/",
      url2017: "https://droidkaigi.github.io/2017/",
      url2018: "https://droidkaigi.jp/2018/",
      url2019: "https://droidkaigi.jp/2019/",
      url2020: "https://droidkaigi.jp/2020/",
      url2020lite: "https://droidkaigi.jp/2020lite/",
      url2021: "https://droidkaigi.jp/2021/",
    },
    copyright: "© 2014- DroidKaigi 実行委員会",
  },
  en: {
    timetable: <Link to="/timetable">Timetable</Link>,
    cfs: (
      <a href="https://docs.google.com/document/d/1Q8YvYBGieQ-oSIArm041jSHPHNZtLTqtwF2CWOGU7m0">
        Call for Speakers
      </a>
    ),
    proposals: (
      <a href="https://docs.google.com/spreadsheets/d/1QeFqzW-AA2afcGRdbacO2Azd83ckxPrdWG4P9dmroxM/edit">
        Proposals
      </a>
    ),
    codeOfConduct: (
      <a href="http://www.association.droidkaigi.jp/en/code-of-conduct.html">
        Code of Conduct
      </a>
    ),
    mailto: <a href="mailto:info@droidkaigi.jp">Contact Us</a>,
    privacyPolicy: (
      <a href="http://www.association.droidkaigi.jp/en/privacy.html">
        Privacy Policy
      </a>
    ),
    license:
      "Android, Google Play, and the Google Play logo are registered trademarks of Google LLC.",
    pastKaigiTitle: "Past DroidKaigi",
    pastKaigis: {
      url2015: "https://droidkaigi.github.io/2015/",
      url2016: "https://droidkaigi.github.io/2016/en/",
      url2017: "https://droidkaigi.github.io/2017/en/",
      url2018: "https://droidkaigi.jp/2018/en/",
      url2019: "https://droidkaigi.jp/2019/en/",
      url2020: "https://droidkaigi.jp/2020/en/",
      url2020lite: "https://droidkaigi.jp/2020lite/en/",
      url2021: "https://droidkaigi.jp/2021/en/",
    },
    copyright: "© 2014- DroidKaigi Committee",
  },
});

// ----------------------------------------------------------------
//     コンポーネント
// ----------------------------------------------------------------

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterMain />
    </FooterContainer>
  );
};

const useFooterStyle = makeStyles((theme) => ({
  section: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  license: {
    [theme.breakpoints.down("xs")]: {
      background: theme.palette.footer2,
      "& > div": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
  },
  pastKaigis: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      order: -1,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
}));
/**
 * フッターのメインコンテンツ(サイト内リンク、過去のDroidKaigi、商標表記)です。
 */
const FooterMain: React.FC = () => {
  const classes = useFooterStyle();
  const text = useText();
  return (
    <Grid container>
      <Grid item xs={12} sm={3} className={classes.section}>
        <LinkList>
          <Item>{text.cfs}</Item>
          <Item>{text.timetable}</Item>
          <Item>{text.proposals}</Item>
        </LinkList>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.section}>
        <LinkList>
          <Item>{text.codeOfConduct}</Item>
          <Item>{text.mailto}</Item>
          <Item>{text.privacyPolicy}</Item>
        </LinkList>
      </Grid>
      <Grid item xs={12} sm={6} container direction="column">
        <Grid item className={`${classes.license} ${classes.section}`}>
          <Caption component="div">
            Android is a trademark of Google LLC. The Android robot is
            reproduced or modified from work created and shared by Google and
            used according to terms described in the Creative Commons 3.0
            Attribution License.
            <br />
            <br />
            {text.license}
          </Caption>
        </Grid>
        <Grid item className={`${classes.pastKaigis} ${classes.section}`}>
          <PastKaigis />
        </Grid>
      </Grid>
    </Grid>
  );
};

// ----------------------------------------------------------------
//     コンポーネント/外枠
// ----------------------------------------------------------------

const useFooterContainerStyle = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    color: theme.palette.primary.contrastText,
    background: theme.palette.footer1,
  },
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  copyright: {
    background: theme.palette.footer2,
  },
}));
/**
 * フッターの外枠(+コピーライト)です。
 */
const FooterContainer: React.FC = ({ children }) => {
  const classes = useFooterContainerStyle();
  const text = useText();
  return (
    <div className={classes.root}>
      <MainContainer className={classes.container}>{children}</MainContainer>
      <Box mt={{ xs: 0, sm: 6 }} py={2} className={classes.copyright}>
        <Caption singleLine component="div" align="center">
          {text.copyright}
        </Caption>
      </Box>
    </div>
  );
};

// ----------------------------------------------------------------
//     コンポーネント/リンク
// ----------------------------------------------------------------

const useLinkListStyles = makeStyles({
  root: {
    margin: 0,
    listStyle: "none",
    paddingLeft: 0,
  },
});
const LinkList: React.FC = ({ children }) => {
  const classes = useLinkListStyles();
  return <ul className={classes.root}>{children}</ul>;
};

const useItemStyles = makeStyles((theme) => ({
  text: {
    lineHeight: "36px",
    "& a": {
      color: theme.palette.primary.contrastText,
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
}));
const Item: React.FC = ({ children }) => {
  const classes = useItemStyles();
  return (
    <li>
      <Body className={classes.text}>{children}</Body>
    </li>
  );
};

// ----------------------------------------------------------------
//     コンポーネント/過去のDroidKaigi
// ----------------------------------------------------------------

/**
 * 過去の DroidKaigi へのリンクです。
 */
const PastKaigis: React.FC = () => {
  const text = useText();
  return (
    <React.Fragment>
      <Caption singleLine>{text.pastKaigiTitle}</Caption>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2015}>
              <img alt="2015" src={d2015} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2016}>
              <img alt="2016" src={d2016} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2017}>
              <img alt="2017" src={d2017} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2018}>
              <img alt="2018" src={d2018} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2019}>
              <img alt="2019" src={d2019} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2020}>
              <img alt="2020" src={d2020} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2020lite}>
              <img alt="2020" src={d2020} />
            </KaigiLink>
          </Grid>
          <Grid item>
            <KaigiLink href={text.pastKaigis.url2021}>
              <img alt="2021" src={d2021} />
            </KaigiLink>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const useKaigiLinkStyles = makeStyles({
  root: {
    "& img": {
      width: 32,
      height: 32,
    },
  },
});
const KaigiLink: React.FC<{ href: string }> = ({ href, children }) => {
  const classes = useKaigiLinkStyles();
  return (
    <a className={classes.root} href={href}>
      {children}
    </a>
  );
};

export default Footer;
