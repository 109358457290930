import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { fontFamilies } from "./theme";

//
// このモジュールでは、主要で使うテキストスタイルをまとめています。
//
// 各画面でフォント設定を直打ちするのは推奨されません。基本的には
// Material-UI のコンポーネント(ButtonやListItemText)で指定されたテキストを
// 使用するか、このモジュールで定義されたコンポーネントを使用します。
//

const useSingleLineStyles = makeStyles((theme) => ({
  text: {
    lineHeight: 1,
  },
  enFont: {
    fontFamily: fontFamilies.en,
  },
  xsAlignLeft: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
}));

type TextProps = TypographyProps & {
  singleLine?: boolean;
  enFont?: boolean;
  xsAlignLeft?: boolean;
};
/**
 * 共通のテキストです。
 *
 * @param singleLine 行間を1にします。
 * @param enFont 言語にかかわらず英語のフォントを使用します。
 * @param xsAlignLeft xsサイズの時だけ左に寄せます。
 */
const Text: React.FC<TextProps> = ({
  singleLine,
  enFont,
  xsAlignLeft,
  className,
  ...props
}) => {
  const classes = useSingleLineStyles();
  const classNames = [className];
  if (singleLine) classNames.push(classes.text);
  if (enFont) classNames.push(classes.enFont);
  if (xsAlignLeft) classNames.push(classes.xsAlignLeft);
  return <Typography className={classNames.join(" ")} {...props} />;
};

// ----------------------------------------------------------------
//     各テキストコンポーネント
// ----------------------------------------------------------------

export const H3En: React.FC<TextProps> = (props) => (
  <Text variant="h3" enFont {...props} />
);

export const H5En: React.FC<TextProps> = (props) => (
  <Text variant="h5" enFont {...props} />
);

export const Headline: React.FC<TextProps> = (props) => (
  <Text variant="subtitle1" {...props} />
);

export const Subheadline: React.FC<TextProps> = (props) => (
  <Text variant="subtitle2" {...props} />
);

export const Body: React.FC<TextProps> = (props) => (
  <Text variant="body2" {...props} />
);

export const Caption: React.FC<TextProps> = (props) => (
  <Text variant="caption" {...props} />
);

// ----------------------------------------------------------------
//     各タイトル形式
// ----------------------------------------------------------------

type TitleProps = {
  children: string;
  marginBottomOffset?: number;
} & TextProps;

export const Title: React.FC<TitleProps> = ({
  children,
  marginBottomOffset,
  ...props
}) => {
  const mbo = marginBottomOffset || 0;

  return (
    <Box mb={{ xs: 2 + mbo, sm: 5 + mbo }}>
      <H3En align="center" color="secondary" xsAlignLeft {...props}>
        <Box component="span" color="primary.main">
          {children.substring(0, 1)}
        </Box>
        {children.substring(1)}
      </H3En>
    </Box>
  );
};

export type SubtitleProps = {
  children: string;
} & TextProps;

export const Subtitle: React.FC<SubtitleProps> = ({ children, ...props }) => {
  return (
    <Box mt={2}>
      <Headline color="secondary" {...props}>
        {children}
      </Headline>
    </Box>
  );
};
