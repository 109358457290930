import React, { createContext } from "react";
import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import { CssBaseline } from "@material-ui/core";
import { useLang } from "./i18n";
import { Properties } from "csstype";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    linkColor: string;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    linkColor?: string;
  }
}

// ----------------------------------------------------------------
//     テーマ設定
// ----------------------------------------------------------------

export const fontFamilies = {
  en: ["Roboto", "sans-serif"].join(","),
  ja: [
    "'Noto Sans JP'",
    "'Hiragino Kaku Gothic Pro'",
    "'ヒラギノ角ゴ Pro W3'",
    "Meiryo",
    "メイリオ",
    "Osaka",
    "'MS PGothic'",
    "arial",
    "helvetica",
    "sans-serif",
  ].join(","),
};

/**
 * 色定義です。
 */
const palette = {
  primary: {
    main: "#00B5E2",
    light: "#C2F3FF",
    dark: "#073042",
    contrastText: "#ffffff",
  },
  light: {
    secondary: {
      main: "#041E42",
      contrastText: "#ffffff",
    },
    header: () => palette.light.secondary.main,
    footer1: () => palette.light.secondary.main,
    footer2: () => "#001129",
    timetableBorder: () => "rgba(0, 0, 0, 0.12)",
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      // 背景色は一部でアルファだけ書き換えるので、rgba を使います。
      default: "rgba(248, 248, 248, 1)",
      paper: "rgba(255, 255, 255, 1)",
    },
  },
  dark: {
    secondary: {
      main: "#ffffff",
      contrastText: "#ffffff",
    },
    header: () => palette.dark.background.paper,
    footer1: () => "#1D1F22",
    footer2: () => palette.dark.background.default,
    timetableBorder: () => "rgba(255, 255, 255, 0.12)",
    text: {
      primary: "rgba(255, 255, 255, 100)",
      secondary: "rgba(255, 255, 255, 0.70)",
      disabled: "rgba(255, 255, 255, 0.50)",
      hint: "rgba(255, 255, 255, 0.50)",
    },
    background: {
      // 背景色は一部でアルファだけ書き換えるので、rgba を使います。
      default: "rgba(17, 19, 22, 1)",
      paper: "rgba(34, 36, 38, 1)",
    },
  },
};

/**
 * dark および light モード向けの設定オブジェクトを生成し、それをマージした
 * オブジェクトを返します。
 */
const createStyles = (
  transformer: (mode: "dark" | "light") => Record<string, Properties>
): Record<string, Properties> => ({
  ...transformer("dark"),
  ...transformer("light"),
});

/**
 * Material-UI の Palette に対する追加の色定義
 */
declare module "@material-ui/core/styles/createPalette" {
  interface PaletteOptions {
    header?: string;
    footer1?: string;
    footer2?: string;
    timetableBorder?: string;
  }

  interface Palette {
    header?: string;
    footer1?: string;
    footer2?: string;
    timetableBorder?: string;
  }
}

/**
 * 通常テーマ/ダークテーマ
 *
 * Material-UI では Material Design に沿ったテーマが用意されていて、
 * テーマをいじる事で各要素のデフォルト色やサイズを変更できます。
 *
 * また、テーマを要素ごとに切り替えたりする事も可能です。これを利用して
 * 通常テーマとダークテーマの切り替えを実現しています。
 *
 * 参考:
 * - Material-UI: https://material-ui.com/customization/theming/
 * - Material Design のテーマ: https://material.io/design/material-theming/implementing-your-theme.html#
 */
const makeTheme = (lang: "en" | "ja", dark: boolean): Theme => {
  const theme = createMuiTheme({
    palette: {
      primary: palette.primary,
      secondary: palette[dark ? "dark" : "light"].secondary,
      header: palette[dark ? "dark" : "light"].header(),
      footer1: palette[dark ? "dark" : "light"].footer1(),
      footer2: palette[dark ? "dark" : "light"].footer2(),
      timetableBorder: palette[dark ? "dark" : "light"].timetableBorder(),
      background: {
        default: palette[dark ? "dark" : "light"].background.default,
        paper: palette[dark ? "dark" : "light"].background.paper,
      },
      type: dark ? "dark" : "light",
    },
    typography: {
      fontFamily: fontFamilies[lang],
      h3: { fontWeight: 500 }, // Title2
      h5: { fontWeight: 500 },
      subtitle1: { fontSize: "1.125rem", fontWeight: 500 }, // Headline
      body2: { lineHeight: 1.7 }, // Body
    },
    shape: {
      borderRadius: 8,
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 120,
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#89DDF1",
          },
        },
      },
      //
      // 簡易モード設定(helpers-initial-mode-*)を使った時のスタイル設定
      //
      MuiPaper: {
        root: createStyles((mode) => ({
          [`.helpers-initial-mode-${mode} &`]: {
            color: palette[mode].text.primary,
            background: palette[mode].background.paper,
          },
        })),
      },
      MuiTypography: {
        colorTextSecondary: createStyles((mode) => ({
          [`.helpers-initial-mode-${mode} &`]: {
            color: palette[mode].text.primary,
          },
        })),
      },
      MuiCssBaseline: {
        "@global": createStyles((mode) => ({
          [`.helpers-initial-mode-${mode}`]: {
            backgroundColor: palette[mode].background.default,
          },
          // className に helpers-hidden-at-first をつける事で、
          // 画面を開いたときに一瞬不適切な表示がされるのを防ぎます。
          [`.helpers-initial-mode-${mode} .helpers-hidden-at-first`]: {
            visibility: "hidden",
          },
        })),
      },
      MuiAppBar: {
        root: createStyles((mode) => ({
          [`.helpers-initial-mode-${mode} &`]: {
            color: palette.dark.text.primary,
            backgroundColor: palette[mode].header(),
          },
        })),
        colorPrimary: {
          backgroundColor: palette[dark ? "dark" : "light"].header(),
        },
      },
    },
  });

  //
  // モバイル用フォント設定
  //
  Object.assign(theme.typography.h3, {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.125rem",
    }, // Title2
  });

  Object.assign(theme.typography.h5, {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem",
    }, // Title2
  });

  return theme;
};

const themes = {
  en: {
    dark: makeTheme("en", true),
    light: makeTheme("en", false),
  },
  ja: {
    dark: makeTheme("ja", true),
    light: makeTheme("ja", false),
  },
};

// ----------------------------------------------------------------
//     現在のテーマへのアクセス
// ----------------------------------------------------------------

const ThemeContext = createContext({
  dark: false,
  setDark: (_dark: boolean): void => {
    throw Error();
  },
});

type GlobalThemeProviderProps = {
  dark: boolean;
  setDark: (dark: boolean) => void;
};

/**
 * テーマと切り替えのための Context を提供します。
 */
export const GlobalThemeProvider: React.FC<GlobalThemeProviderProps> = ({
  dark,
  setDark,
  children,
}) => (
  <ThemeContext.Provider value={{ dark, setDark }}>
    <ThemeProvider dark={dark}>
      <Helmet>
        {/*
         * Roboto および Noto を Google Font から選択して ダウンロード
         * 参考: https://fonts.google.com/specimen/Noto+Sans+JP?selection.family=Noto+Sans+JP:400,500|Roboto:400,500
         */}
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500|Roboto:400,500&display=swap&subset=japanese"
          rel="stylesheet"
        />
      </Helmet>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </ThemeContext.Provider>
);

export const ThemeProvider: React.FC<{ dark: boolean }> = ({
  dark,
  children,
}) => {
  const lang = useLang();
  return (
    <MuiThemeProvider theme={themes[lang][dark ? "dark" : "light"]}>
      {children}
    </MuiThemeProvider>
  );
};

/**
 * rgba の値のアルファ値を書き換えます。
 */
export const rgbaAlpha = (rgba: string, alpha: string): string => {
  return rgba.replace(/, *[0-9.]+\)/, `, ${alpha})`);
};
