import React from "react";
import ogpImage from "../../images/ogp.png";
import favicon from "../../images/favicon.png";
import Helmet from "react-helmet";
import { usePagePath, makeText, useLang } from "helpers/i18n";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  en: {
    title: "DroidKaigi 2020",
    description:
      "DroidKaigi is a conference tailored for Android developers. It's scheduled to take place on the 20 and 21 of February 2020.",
  },
  ja: {
    title: "DroidKaigi 2020",
    description:
      "DroidKaigiはエンジニアが主役のAndroidカンファレンスです。Android技術情報の共有とコミュニケーションを目的に、2020年2月20日(木)、21日(金)の2日間開催します。",
  },
});

// ----------------------------------------------------------------
//     コンポーネント
// ----------------------------------------------------------------

const Metadata: React.FC<{ title?: string; description?: string }> = ({
  title,
  description,
}) => {
  const lang = useLang();
  const pathname = usePagePath();
  const text = useText();
  const fullTitle = title ? `${title} | ${text.title}` : text.title;
  const prefix = "https://droidkaigi.jp";

  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{fullTitle}</title>
      <meta name="description" content={text.description} />
      <link rel="icon" type="image/png" href={favicon} />
      {/* Required Open Graph Info */}
      <meta property="og:title" content={fullTitle} />
      <meta
        property="og:description"
        content={description || text.description}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${prefix}/2020${pathname}`} />
      <meta property="og:image" content={`${prefix}${ogpImage}`} />
      <meta property="og:site_name" content="DroidKaigi" />
      {/* Twitter Cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@DroidKaigi" />
    </Helmet>
  );
};

export default Metadata;
