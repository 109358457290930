import React, { useCallback, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import LanguageIcon from "@material-ui/icons/Translate";
import DoneIcon from "@material-ui/icons/Done";
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import { usePagePath, resolve, useLang } from "helpers/i18n";
import GatsbyLink from "gatsby-link";

const useLangMenuStyle = makeStyles((theme) => ({
  // デフォルトではアイコンとチェックマークがかなり空いていたので、狭めました。
  checkIcon: {
    minWidth: theme.spacing(4),
  },
}));

const LangMenu: React.FC = () => {
  const pagePath = usePagePath();
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>();
  const open = Boolean(anchorEl);
  const classes = useLangMenuStyle();
  const lang = useLang();

  const onOpenLangMenu = useCallback(
    (event: { currentTarget: HTMLElement }) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const onCloseLangMenu = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  return (
    <React.Fragment>
      <IconButton
        edge="end"
        color="inherit"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={onOpenLangMenu}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={onCloseLangMenu}
      >
        <MenuItem
          component={GatsbyLink}
          to={resolve("ja", pagePath)}
          onClick={onCloseLangMenu}
        >
          <ListItemIcon className={classes.checkIcon}>
            <React.Fragment>{lang === "ja" && <DoneIcon />}</React.Fragment>
          </ListItemIcon>
          <ListItemText primary="日本語" />
        </MenuItem>
        <MenuItem
          component={GatsbyLink}
          to={resolve("en", pagePath)}
          onClick={onCloseLangMenu}
        >
          <ListItemIcon className={classes.checkIcon}>
            <React.Fragment>{lang === "en" && <DoneIcon />}</React.Fragment>
          </ListItemIcon>
          <ListItemText primary="English" />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default LangMenu;
