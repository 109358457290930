import React from "react";
import { useLang, resolve } from "./i18n";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";

/**
 * リンクを提供します。
 *
 * リンク先の URL は現在の言語設定を考慮して切り替わります。
 */
export default function Link<T>(props: GatsbyLinkProps<T>): React.ReactElement {
  const lang = useLang();
  const p: { to: string } = { ...props, to: resolve(lang, props.to) };
  return <GatsbyLink {...p} />;
}

export const ExternalLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({
  href,
  children,
  ...props
}) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href} {...props}>
      {children || href}
    </a>
  );
};
