import React from "react";
import { makeStyles } from "@material-ui/core";
import Container, { ContainerProps } from "@material-ui/core/Container";

const useMainContainerStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
}));

/**
 * コンテンツ領域を表す共通コンポーネントです。
 *
 * 同様の機能を Material-UI が提供していますが、特にサイズを調整したい場合を除き、
 * このコンポーネントを使うことが推奨されます。
 *
 * PCサイズの場合、840px 程度の幅で中央寄せされます。
 * それ以下の場合はある程度のパディングが付与されます。
 */
const MainContainer: React.FC<ContainerProps> = (props) => {
  const classes = useMainContainerStyles();
  return <Container className={classes.root} maxWidth="md" {...props} />;
};

export default MainContainer;
