import React, { useContext } from "react";
import { Location } from "@reach/router";
import { withPrefix } from "gatsby-link";

//
// 国際化対応をするための処理を提供します。
//
// ### 使い方
//
// `makeText()` 関数のドキュメントを参考にしてください。
//
//
// ### 国際化対応の実装方法
//
// Gatsby 自身は国際化機能を提供していないので、自前で用意しています。
// 手軽なものがなかったので国際化のライブラリも使っていません。
//
// 判別方法は「`/en` で始まるパスは英語サイト、それ以外は日本語サイト」というルールにしています。
//

/**
 * サイトの言語指定部分の正規表現です。
 *
 * 例えば以下のような文字列にマッチします。
 *
 * - `/` … 日本語ページ(常にマッチ)
 * - `/en/` … 英語ページ(常にマッチ)
 * - `/2018/` … 日本語ページ(プレフィックスとして /2018 が指定された場合)
 * - `/2018/en/` … 英語ページ(プレフィックスとして /2018 が指定された場合)
 */
const prefix = new RegExp(`^${withPrefix("(?:en/|en$)?")}`);

/**
 * 英語サイトの言語指定部分のパターンです。
 *
 * 例えば以下のような文字列にマッチします。
 *
 * - `/en/` … 英語ページ(常にマッチ)
 * - `/2018/en/` … 英語ページ(プレフィックスとして /2018 が指定された場合)
 */
const enPrefix = new RegExp(`^${withPrefix("/(?:en/|en$)")}`);

// ----------------------------------------------------------------
//     高レベル API
// ----------------------------------------------------------------

export type LangType = "ja" | "en";

/**
 * 国際化テキストを提供するコンポーネントを作成します。
 *
 * 国際化テキストには、通常のテキストの他に、React の Element を格納することもできます。
 *
 * @example
 * const useText = makeText({
 *   en: {
 *     title: `English Page`,
 *     ...
 *   },
 *   ja: {
 *     title: `日本語ページ`,
 *     ...
 *   }
 * });
 *
 * export default function index() {
 *   const text = useText();
 *   return (<h1>{text.title}</h1>);
 * }
 *
 */
export const makeText = <T extends Record<string, unknown>>(texts: {
  ja: T;
  en: T;
}): (() => T) => {
  return () => {
    const lang = useLang();
    return texts[lang];
  };
};

// ----------------------------------------------------------------
//     低レベル API
// ----------------------------------------------------------------

/**
 * location を元に言語を取得します。
 */
const lang = (pathname: string): LangType =>
  pathname.match(enPrefix) ? "en" : "ja";

/**
 * 現在のページの言語を取得します。
 */
export const useLang = (): LangType => {
  const pathname = useContext(I18nContext);
  return lang(pathname);
};

/**
 * 指定のパスに対して現在の言語設定のプレフィックスを追加します。
 */
export const resolve = (lang: LangType, path: string): string => {
  if (!path.match(/^\//)) throw Error(`path must begin with '/': ${path}`);
  return lang === "en" ? `/en${path}` : path;
};

/**
 * 現在の言語設定のプレフィックスを取り除いたパスを返します。
 */
export const usePagePath = (): string => {
  const pathname = useContext(I18nContext);
  return pathname.replace(prefix, "/");
};

// ----------------------------------------------------------------
//     コンテキスト
// ----------------------------------------------------------------

const I18nContext = React.createContext<string>("/");
/**
 * i18n のモジュールを使う場合に、トップにこのコンポーネントを配置します。
 */
export const I18nContextProvider: React.FC = ({ children }) => (
  <Location>
    {({ location }) => (
      <I18nContext.Provider value={location.pathname}>
        {children}
      </I18nContext.Provider>
    )}
  </Location>
);
